import * as React from 'react';
import { ReactNode } from 'react';
import { Link } from './link';
import {
  BLOCKS,
  MARKS,
  Document,
  Node,
  INLINES,
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import GatsbyImage from 'gatsby-image';
import { defaultLangKey, Language } from '../data/languages';

const Bold = ({ children }: { children: ReactNode }) => (
  <span className="bold">{children}</span>
);
const Italic = ({ children }: { children: ReactNode }) => (
  <span className="italic">{children}</span>
);
const Underline = ({ children }: { children: ReactNode }) => (
  <span className="underline">{children}</span>
);
const Code = ({ children }: { children: ReactNode }) => (
  <span className="code">{children}</span>
);

const Paragraph = ({
  children,
  className,
  style,
}: {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div className={`${className ?? ''} paragraph`} style={style}>
    {children}
  </div>
);
const H1 = ({ children }: { children: ReactNode }) => <h1>{children}</h1>;
const H2 = ({ children }: { children: ReactNode }) => <h2>{children}</h2>;
const H3 = ({ children }: { children: ReactNode }) => <h3>{children}</h3>;
const H4 = ({ children }: { children: ReactNode }) => <h4>{children}</h4>;
const H5 = ({ children }: { children: ReactNode }) => <h5>{children}</h5>;
const H6 = ({ children }: { children: ReactNode }) => <h6>{children}</h6>;
const Blockquote = ({ children }: { children: ReactNode }) => (
  <blockquote>{children}</blockquote>
);
const Ul = ({ children }: { children: ReactNode }) => <ul>{children}</ul>;
const Li = ({ children }: { children: ReactNode }) => <li>{children}</li>;

const Audio = ({ node, lang }: { node: Node; lang: Language }) => (
  <Paragraph>
    <audio controls>
      <source src={node?.data?.target?.fields?.file?.[lang].url} />
    </audio>
  </Paragraph>
);
const Video = ({ node, lang }: { node: Node; lang: Language }) => (
  <Paragraph>
    <video src={node?.data?.target?.fields?.file?.[lang].url} />
  </Paragraph>
);
const Image = ({ node, lang }: { node: Node; lang: Language }) => {
  return (
    <Paragraph className={'image'} style={{ margin: '5px 0', width: '100%' }}>
      <GatsbyImage
        fluid={{
          aspectRatio: 1,
          sizes: '',
          srcSet: '',
          src: node?.data?.target?.fields?.file?.[lang].url,
        }}
        backgroundColor="#eee"
        alt={node?.data?.target?.fields?.title?.[lang]}
      />
    </Paragraph>
  );
};

type VideoProvider = 'youtube' | 'vimeo';
type VideoSrc = string;

const YoutubeEmbed = ({ src }: { src: VideoSrc }) => {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const match = src.match(regExp);
  const videoId = match?.[1] ?? null;
  return (
    <iframe
      className="youtube-player"
      width="640"
      height="390"
      src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&autohide=1&rel=0&playsinline=1`}
      allowFullScreen={true}
      style={{ border: 0 }}
    />
  );
};

const VimeoEmbed = ({ src }: { src: VideoSrc }) => {
  const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const match = src.match(regExp);
  const videoId = match?.[3] ?? null;
  return (
    <iframe
      className="vimeo-player"
      src={`https://player.vimeo.com/video/${videoId}`}
      allowFullScreen={true}
      style={{ border: 0 }}
    />
  );
};

const VideoEmbed = ({
  provider,
  src,
}: {
  provider: VideoProvider;
  src: VideoSrc;
}) => {
  let embed = null;
  switch (provider) {
    case 'youtube':
      embed = <YoutubeEmbed src={src} />;
      break;
    case 'vimeo':
      embed = <VimeoEmbed src={src} />;
      break;
  }

  return (
    <div className={`video wide`}>
      <span
        className={`embed-${provider}`}
        style={{ textAlign: 'center', display: 'block' }}
      >
        {embed}
      </span>
    </div>
  );
};

const createOptions = (lang: Language): Options => ({
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    [MARKS.CODE]: text => <Code>{text}</Code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      // Youtube
      if (
        node.data?.uri.match(
          /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
        )
      ) {
        return <VideoEmbed provider="youtube" src={node.data.uri} />;
      }

      if (node.data?.uri.match(/^(https?\:\/\/)?(www\.)?(vimeo\.com)\/.+$/)) {
        return <VideoEmbed provider="vimeo" src={node.data.uri} />;
      }

      return (
        <a href={node.data.uri} target="_blank">
          node.data.uri
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <Link to={'/'}>{children}</Link>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <Link to={'/'}>{children}</Link>
    ),
    [BLOCKS.DOCUMENT]: (node, children) => <div>{children}</div>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.UL_LIST]: (node, children) => <Ul>{children}</Ul>,
    [BLOCKS.OL_LIST]: (node, children) => <Li>{children}</Li>,
    [BLOCKS.LIST_ITEM]: (node, children) => children,
    [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
    [BLOCKS.HR]: node => <hr />,
    [BLOCKS.EMBEDDED_ENTRY]: node => null,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const contentType =
        node?.data?.target?.fields?.file[defaultLangKey]?.contentType;
      const mimeTypePrefix = contentType?.substring(
        0,
        contentType?.indexOf('/')
      );

      if (mimeTypePrefix === 'audio') {
        return <Audio node={node} lang={lang} />;
      }

      if (mimeTypePrefix === 'video') {
        return <Video node={node} lang={lang} />;
      }

      if (mimeTypePrefix === 'image') {
        return <Image node={node} lang={lang} />;
      }

      return null;
    },
  },
});

export const renderRichText = ({
  document,
  lang,
}: {
  document: Document;
  lang: Language;
}) => {
  const options = createOptions(lang);
  return documentToReactComponents(document, options);
};
