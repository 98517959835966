import * as React from 'react';
import { join } from 'path';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import { defaultLangKey, Language } from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { renderRichText } from '../utils/rich-editor';
import tw from 'twin.macro';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import ContentWidth from '../components/contentWidth';
import { seoData } from '../data/seo';

type Props = {
  data: {
    contentfulBlogPost: {
      title: string;
      slug: string;
      node_locale: Language;
      content: { json: Document };
      heroImage: {
        fluid: FluidObject;
      };
      publishDate: string;
    };
  };
  location: Location;
};

function Post({
  data: {
    contentfulBlogPost: {
      title,
      slug,
      node_locale,
      content,
      heroImage,
      publishDate,
    },
  },
  location,
}: Props) {
  return (
    <Layout
      lang={node_locale}
      location={location}
      pathnameWithoutLang={join(models.dynamic.blogPost.path, slug)}
    >
      <SEO
        title={`${title} - Posts`}
        description={slug}
        meta={[]}
        lang={node_locale}
        pathname={location.pathname}
      />
      <ContentWidth>
        <article>
          <div>
            <div css={tw`w-full mx-auto mb-8 text-center`}>
              <h1
                className={`postTitle`}
                css={tw`text-xl md:text-3xl font-bold mb-2 border-none`}
                style={{ paddingLeft: 0 }}
              >
                {title}
              </h1>
              <time css={tw`text-sm md:text-base text-title italic`}>
                {publishDate}
              </time>
            </div>
            <BackgroundImage
              Tag="div"
              fluid={heroImage.fluid}
              fadeIn={true}
              css={tw`max-w-screen-lg h-hero w-full flex-none bg-cover text-center overflow-hidden bg-gray-200 mx-auto mb-16`}
            />
            <div css={tw`w-full max-w-screen-sm mx-auto`}>
              {renderRichText({ document: content.json, lang: node_locale })}
            </div>
          </div>
        </article>
      </ContentWidth>
    </Layout>
  );
}

export default Post;

export const pageQuery = graphql`
  query blogPostQuery($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      slug
      node_locale
      heroImage {
        fluid(maxWidth: 2000) {
          aspectRatio
          sizes
          src
          srcSet
        }
      }
      publishDate(formatString: "YYYY/MM/DD")
      content {
        json
      }
    }
  }
`;
